import jsPDF from "jspdf";
import { toast } from "react-toastify";

const generateCurriculumPDF = async (curriculoId) => {
  try {
    // Obter o token armazenado no localStorage
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Token não encontrado. Faça login novamente.");
      return;
    }

    // Fetch currículo data da API com token de autenticação
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/curriculos/${curriculoId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Inclui o token no cabeçalho
        },
      }
    );

    if (response.status === 401) {
      toast.error("Sessão expirada. Faça login novamente.");
      return;
    }

    if (!response.ok) {
      toast.error("Erro ao buscar os dados do currículo.");
      return;
    }

    const curriculo = await response.json();

    // Use os dados do `userId` populados ou fallback do localStorage
    const storedUser = JSON.parse(localStorage.getItem('user')) || {};
    const userName = curriculo.userId?.name || storedUser.name || "Nome não informado";
    const userEmail = curriculo.userId?.email || storedUser.email || "Email não informado";

    const imageUrl = `${process.env.REACT_APP_API_IMG}/assets/curriculos/${curriculo.foto}`;

    const doc = new jsPDF();

    // Adiciona a imagem do candidato
    if (curriculo.foto) {
      const image = await fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        });

      doc.addImage(image, "JPEG", 10, 10, 30, 30);
    }

    // Adiciona o título com nome e contatos
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#333");
    doc.text(userName, 50, 16);

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.setTextColor("#666");
    // Exibe as redes sociais no formato concatenado
    // Verifica a existência do LinkedIn ou seleciona a primeira rede social disponível
    let redeSocial = "N/A";
    if (curriculo.redesSociais && curriculo.redesSociais.length > 0) {
      const linkedin = curriculo.redesSociais.find((rede) => rede.tipo.toLowerCase() === "linkedin");
      redeSocial = linkedin ? linkedin.url : curriculo.redesSociais[0].url; // Prioriza LinkedIn ou pega a primeira rede
    }

    // Adiciona ao PDF
    doc.text(redeSocial, 50, 22);
    doc.text(curriculo.telefone || "N/A", 50, 28);
    doc.text(userEmail || "N/A", 50, 34);
    doc.text(curriculo.endereco || "N/A", 50, 40)

    // Linha divisória
    doc.setDrawColor(0);
    doc.setLineWidth(0.1);
    doc.line(10, 45, 200, 45);

    let yPosition = 50;

    // Adiciona o resumo
    if (curriculo.perfil) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Resumo", 10, 52);

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      // Remove as tags HTML antes de adicionar ao PDF
      const plainText = curriculo.perfil.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' '); // Remove as tags HTML
      doc.text(plainText, 10, yPosition + 8, { maxWidth: 190 });
      yPosition += 23;
    }

    // Adiciona experiência profissional
    if (curriculo.experiencia && curriculo.experiencia.length > 0) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Experiência Profissional", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      curriculo.experiencia.forEach((exp, index) => {
        // Remove as tags HTML antes de adicionar ao PDF
        const plainText = exp.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' '); // Remove todas as tags HTML
        doc.text(`- ${plainText}`, 10, yPosition + index * 7, { maxWidth: 190 });
      });
      yPosition += curriculo.experiencia.length * 8;
    }

    // Adiciona formação acadêmica
    if (curriculo.formacao && curriculo.formacao.length > 0) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Formação Acadêmica", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      curriculo.formacao.forEach((formacao, index) => {
        // Remove as tags HTML antes de adicionar ao PDF
        const plainText = formacao.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' '); // Remove todas as tags HTML
        doc.text(`- ${plainText}`, 10, yPosition + index * 7, { maxWidth: 190 });
        // doc.text(`- ${formacao}`, 10, yPosition + index * 7, { maxWidth: 190 });
      });
      yPosition += curriculo.formacao.length * 8;
    }

    // Adiciona cursos
    if (curriculo.curso && curriculo.curso.length > 0) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Outros Cursos", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      curriculo.curso.forEach((curso, index) => {
        // Remove as tags HTML antes de adicionar ao PDF
        const plainText = curso.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' '); // Remove todas as tags HTML
        doc.text(`- ${plainText}`, 10, yPosition + index * 7, { maxWidth: 190 });
        // doc.text(`- ${curso}`, 10, yPosition + index * 7, { maxWidth: 190 });
      });
      yPosition += curriculo.curso.length * 8;
    }

    // Mapeamento das áreas de interesse para descrições mais amigáveis
    const areaLabels = {
      ti: "Tecnologia da Informação",
      administrativa: "Administrativa",
      callCenter: "Atendimento em Call Center",
      atendimentoPublico: "Atendimento ao Público",
      vendedor: "Vendas",
    };

    // Mapeamento das áreas de interesse para descrições mais amigáveis
    const cidadeLabels = {
      goiania: "Goiânia/Aparecida de Goiânia",
      jaragua: "Matriz Jaraguá",
      ceres: "Ceres, Carmo do Rio Verde, Nova Glória e Rianápolis",
      uruana: "Uruana e Braslandia",
      uruacu: "Uruaçu",
    };


    // Adiciona áreas de interesse
    if (curriculo.areasInteresse) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Áreas de Interesse", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      Object.entries(curriculo.areasInteresse).forEach(([area, value]) => {
        if (value) {
          const areaDescription = areaLabels[area] || area; // Usa o mapeamento ou o valor original como fallback
          doc.text(`- ${areaDescription}`, 10, yPosition, { maxWidth: 190 });
          yPosition += 7; // Move para a próxima linha
        }
      });

      yPosition += 5; // Adiciona um pequeno espaço extra após a lista
    }

    // Adiciona cidades de interesse
    if (curriculo.cidadesInteresse) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Cidades de Interesse", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      Object.entries(curriculo.cidadesInteresse).forEach(([cidade, value]) => {
        if (value) {
          const cidadeDescription = cidadeLabels[cidade] || cidade; // Usa o mapeamento ou o valor original como fallback
          doc.text(`- ${cidadeDescription}`, 10, yPosition, { maxWidth: 190 });
          yPosition += 7; // Move para a próxima linha
        }
      });

      yPosition += 5; // Adiciona um pequeno espaço extra após a lista
    }

    // Adiciona redes sociais
    if (curriculo.redesSociais && curriculo.redesSociais.length > 0) {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(11);
      doc.text("Redes Sociais", 10, yPosition);

      yPosition += 7;

      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      curriculo.redesSociais.forEach((rede) => {
        doc.text(`- ${rede.tipo}: ${rede.url}`, 10, yPosition, { maxWidth: 190 });
        yPosition += 7; // Move para a próxima linha
      });

      yPosition += 5; // Adiciona um pequeno espaço extra após a lista
    }

    // Linha final para separar informações adicionais
    doc.line(10, yPosition, 200, yPosition);
    yPosition += 10;

    // Informações adicionais
    // doc.setFont("Helvetica", "bold");
    // doc.setFontSize(11);
    // doc.text("Informações Adicionais", 10, yPosition);

    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(10);
    // doc.text(
    //   "Disponibilidade imediata para trabalhar. Outros detalhes disponíveis sob consulta.",
    //   10,
    //   yPosition + 10,
    //   { maxWidth: 190 }
    // );

    // (Continue com o restante da geração do PDF...)
    window.open(doc.output("bloburl"), "_blank");
  } catch (error) {
    console.error("Erro ao gerar PDF:", error);
    toast.error("Erro ao gerar o PDF. Tente novamente.");
  }
};

export default generateCurriculumPDF;
