import React, { useState, useEffect } from 'react';
import NavBar from './components/Navbar';
import NavBarMobile from './components/NavBarMobile/NavBarMobile';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Tv from "./pages/Tv";
import Telefone from "./pages/Telefone";
import Footer from './components/Footer';
import InternetPage from './pages/Internet';
import InternetRural from '../src/components/ComponentInternetRural/InternetRural';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ComboTV from './components/ComponetTV/ComboTV/ComboTV';
import ContactPage from './pages/ContactPage/ContactPage';
// import { useMediaQuery } from 'react-responsive';
import Cidades from './components/Cidades/Cidades';
import Movel from './pages/Movel/Movel';
import Curriculo from './pages/Curriculo/Curriculo';
import { ToastContainer, toast } from 'react-toastify';
import VideoGallery from './pages/VideoGallery/VideoGallery';
import About from './pages/About/About';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import CookieConsent from './components/CookieConsent/CookieConsent';
import CardDisplay from './pages/CardDisplay/CardDisplay';
import Chatbot from './pages/Chatbot/Chatbot';
import CurriculoView from './pages/CurriculoView/CurriculoView';
import MultiStepForm from './pages/MultiStepForm/MultiStepForm';
import Produtos from './pages/Produtos/Produtos';
import AppRedirect from './components/AppRedirect/AppRedirect';
import PdfList from './pages/PdfList/PdfList';
import AppRedirectPlay from './components/AppRedirect/AppRedirectPlay';
import LoginComponent from './components/LoginComponent/LoginComponent';
import ManageCurriculum from './pages/Curriculo/ManageCurriculum';
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { AuthProvider } from "./context/AuthContext/AuthContext";
import RegisterSite from './pages/RegisterSite/RegisterSite';
import TrabalheConosco from './pages/Curriculo/TrabalheConosco';

function App() {
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  const [cookieConsent, setCookieConsent] = useState(false);

  useEffect(() => {
    const registerVisit = async () => {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/visit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Erro ao registrar visita:", error);
      }
    };

    registerVisit();
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          {/* <ToastContainer /> */}
          <CookieConsent onConsentGiven={setCookieConsent} />
          {/* {isMobile ? <NavBarMobile /> : <NavBar />} */}
          <NavBar cookieConsent={cookieConsent} />
          <TrabalheConosco />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/internet" element={<InternetPage />} />
            <Route exact path='/internet-rural' element={<InternetRural />} />
            <Route exact path="/tvs" element={<Tv />} />
            <Route exact path="/telefone" element={<Telefone />} />
            <Route exact path="/telefone-movel" element={<Movel />} />
            <Route exact path="/combos" element={<ComboTV />} />
            <Route exact path='/contato' element={<ContactPage />} />
            <Route exact path='/cidades' element={<Cidades />} />
            <Route exact path='/videos' element={<VideoGallery />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/appcliente' element={<AppRedirect />} />
            <Route exact path='/appplay' element={<AppRedirectPlay />} />
            <Route exact path='/produtos' element={<Produtos />} />
            <Route exact path='/Documentacao' element={<PdfList />} />
            <Route exact path='/login' element={<LoginComponent />} />
            <Route exact path='/register-site' element={<RegisterSite />} />
            <Route exact path="/editar-curriculo" element={<PrivateRoute><ManageCurriculum /></PrivateRoute>} />
            <Route exact path='/curriculo/:id' element={<CurriculoView />} />
            <Route exact path='/criar-curriculo' element={<PrivateRoute><Curriculo /></PrivateRoute>} />
            <Route exact path="/*" element={<NotFoundPage />} />
          </Routes>
          {/* <CardDisplay /> */}
          <Chatbot />
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
