import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Bots from '../../assets/images/job.png';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

import SeparatorBar from '../../components/Separator/SeparatorBar';
import Avatar from '../../assets/icons/avatar-do-usuario.png';

import './RegisterSite.css';

const RegisterSite = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register-site`, {
        name,
        email,
        password,
        confPassword,
      });

      if (response.status === 201) {
        setSuccess('Usuário registrado com sucesso!');
        toast.success('Usuário registrado com sucesso!', { position: 'top-center' });
        setTimeout(() => {
          navigate('/login'); // Redireciona para a página de login após 2 segundos
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message); // Mostra mensagem do backend
      } else {
        setError('Erro ao registrar usuário. Tente novamente.');
      }
    }
  };

  return (
    <div className='login-container-bg'>
      <ToastContainer />
      <div className="container">
        <div className="content-login mx-auto text-center" style={{ width: "80%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                <h2 className='bots'>Registre-se agora para acessar a área de currículos e dar o próximo passo na sua carreira!</h2>
                <div className="col-sm-12 col-md-6">
                  <div className="avatar">
                    <img src={Bots} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 gap-5 pr-5">
                  {error && <Alert variant="danger">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}

                  <div className='register-title'>
                    <img src={Avatar} alt="" />
                    <h2>Cadastrar no site</h2>
                  </div>
                  <SeparatorBar />
                  <Form onSubmit={handleRegister}>
                    <Form.Group controlId="name">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder='Digite seu nome'
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Digite seu email'
                      />
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder=' Senha'
                      />
                    </Form.Group>

                    <Form.Group controlId="confPassword">
                      <Form.Label>Confirmar Senha</Form.Label>
                      <Form.Control
                        type="password"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        required
                        placeholder=' Confirmar senha'
                      />
                    </Form.Group>

                    <Button className='btn-login' variant="primary" type="submit">
                      Registrar
                    </Button>
                  </Form>
                  <div className='signIn'>
                    <p>Já sou cadastrado! <a href="/login">Login</a> </p>
                  </div>
                </div>
                <p>Se você busca crescimento profissional, desafios estimulantes e deseja fazer a diferença, cadastre no site para preencher seu currículo e venha construir o futuro conosco!</p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RegisterSite;
