import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import generateCurriculumPDF from './GenerateCurriculumPDF'; // Importe a função para gerar o PDF
import { Button, Table, Spinner } from "react-bootstrap";
import BG from '../../assets/images/Curriculo_cleanup.png';
import Click from '../../assets/icons/Click.png';
import Documento from '../../assets/icons/doc-Photoroom.png';
import SeparatorBar from '../../components/Separator/SeparatorBar';
import { toast, ToastContainer } from "react-toastify";
import './Curriculo.css';

const ManageCurriculum = () => {
  const [curriculo, setCurriculo] = useState(null); // Alterado para objeto único
  const [user, setUser] = useState(null); // Novo estado para armazenar o usuário
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalCurriculoId, setDeleteModalCurriculoId] = useState(null);
  const [generatingPdf, setGeneratingPdf] = useState(false); // Estado para controle do PDF
  const navigate = useNavigate();

  // Função para buscar o usuário logado
  const fetchUser = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  };

  // Função para buscar currículo do usuário logado
  const fetchCurriculo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCurriculo(response.data); // Atualiza o estado com o currículo
    } catch (error) {
      console.error('Erro ao buscar currículo:', error);
      // toast.error('Erro ao buscar currículo. Tente novamente.');
    }
  };

  useEffect(() => {
    fetchUser(); // Busca o usuário ao montar o componente
    fetchCurriculo(); // Busca currículo ao montar o componente
  }, []);

  // Função para excluir um currículo
  const handleDelete = async (curriculoId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autenticação não encontrado. Faça login novamente.");
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/curriculos/${curriculoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Atualiza o estado após a exclusão
      setCurriculo(null); // Remove o currículo do estado

      toast.success('Currículo excluído com sucesso!', {
        autoClose: 500
      });
    } catch (error) {
      console.error("Erro ao excluir currículo:", error);
      toast.error(
        error.response?.data?.message || "Erro ao excluir o currículo. Tente novamente."
      );
    } finally {
      setShowDeleteModal(false); // Fecha o modal independentemente do resultado
    }
  };

  // Função para gerar o PDF
  const handleGeneratePdf = async () => {
    setGeneratingPdf(true); // Define o currículo em processo de geração do PDF
    try {
      if (curriculo) {
        await generateCurriculumPDF(curriculo._id); // Chama a função de geração de PDF
        // toast.success('PDF gerado com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
      toast.error('Erro ao gerar o PDF. Tente novamente.');
    } finally {
      setGeneratingPdf(false); // Finaliza o controle do PDF
    }
  };

  // Função para redirecionar para a edição
  const handleEdit = () => {
    if (curriculo) {
      navigate(`/editar-curriculo/${curriculo._id}`);
    }
  };

  // Função para redirecionar para criar currículo
  const handleCreate = () => {
    navigate("/criar-curriculo");
  };

  return (
    <div className="container mt-4">
      <div className="user-info">
        {user && (
          <div>
            <h5>Bem-vindo, {user.name}</h5>
            <p>Email: {user.email}</p>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="curriculo-foto">
            <img src={BG} alt="" />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="editar-curriculo">
            <h3>Destaque-se com seu Currículo Profissional!</h3>
            <p>
              O primeiro passo para conquistar a sua vaga começa aqui! Preencha seu currículo de forma
              rápida e fácil. Uma apresentação profissional é fundamental para destacar suas habilidades, experiências
              e interesses. Não perca a oportunidade de mostrar o melhor de si para nossos recrutadores.
            </p>
            <SeparatorBar />
            <div className="editar-curriculo-list">
              <span className="editar-curriculo-list-int"> <img src={Documento} alt="" /> Em poucos minutos, você terá seu currículo pronto.</span>
              <span className="editar-curriculo-list-int"> <img src={Documento} alt="" /> Um bom currículo aumenta suas chances de sucesso.</span>
              <span className="editar-curriculo-list-int"> <img src={Documento} alt="" /> Todos os seus dados organizados em um só lugar.</span>
            </div>
            {!curriculo ? (
              <>
                <SeparatorBar /><br />
                <span className="editar-curriculo-list-int"> <img src={Click} alt="" /> Clique no botão abaixo e dê o próximo passo para transformar sua carreira</span>
                <br /><br />
                <Button variant="primary" onClick={handleCreate}>
                  Criar Currículo
                </Button>
              </>
            ) : (
              <>
                <SeparatorBar /><br />
                <span>
                🎉 Parabéns! Seu currículo foi salvo com sucesso. Role a página para visualizá-lo, editá-lo ou excluí-lo, se necessário. Boa sorte na sua jornada profissional! 🚀
                </span>
              </>
            )
            }
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
        {!curriculo ? (
          <div className="cadastrar-curriculo">
            <p>Nenhum currículo encontrado.</p>
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th className="botao-td">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{curriculo.nome || 'Nome não informado'}</td>
                <td>{curriculo.email || 'Email não informado'}</td>
                <td>{curriculo.telefone || 'Telefone não informado'}</td>
                <td>
                  <div className="botao-curriculo">
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={handleGeneratePdf}
                      disabled={generatingPdf}
                    >
                      {generatingPdf ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <div className="center-icon-button">
                            <i class="fa fa-spinner" aria-hidden="true"></i>
                          </div>
                        </>
                      ) : (
                        <div className="center-icon-button">
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </div>
                      )}
                    </Button>
                    {/* <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleEdit}
                    >
                      <div className="center-icon-button">
                        <i className="fa fa-edit" aria-hidden="true"></i>
                      </div>
                    </Button>{' '} */}
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setDeleteModalCurriculoId(curriculo._id);
                      }}
                    >
                      <div className="center-icon-button">
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </div>
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="trash">
              <i class="fa fa-trash-o" aria-hidden="true"> </i>
              <span>Tem certeza que deseja excluir?</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => setShowDeleteModal(false)}>
              Cancelar
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                handleDelete(deleteModalCurriculoId);
              }}
            >
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div >
  );
};

export default ManageCurriculum;
