import React, { useState } from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Experiencia from '../../assets/curriculo/experiencia.jpg';
import Perfil from '../../assets/curriculo/perfil.jpg';
import Formacao from '../../assets/curriculo/formacao.jpg';
import Curso from '../../assets/curriculo/cursos.jpg';

// Objeto contendo as orientações mapeadas por identificadores
const orientations = {
  formacao: {
    title: "Exemplo de como preencher o campo Formação:",
    content: "Preencha com suas formações acadêmicas, indicando cursos, instituições e datas de conclusão.",
    image: Formacao,
  },
  curso: {
    title: "Exemplo de como preencher o campo Curso:",
    content: "Liste os cursos relevantes que você realizou, incluindo nome do curso e instituição.",
    image: Curso,
  },
  perfil: {
    title: "Exemplo de como preencher o campo Perfil:",
    content: "Descreva brevemente suas habilidades e objetivos profissionais.",
    image: Perfil,
  },
  experiencia: {
    title: "Exemplo de como preencher o campo Experiência:",
    content: "Liste suas experiências profissionais, com nome da empresa, cargo e período.",
    image: Experiencia,
  },
};

const PopoverGeneric = ({ fieldKey }) => {
  const [buttonText, setButtonText] = useState('Exemplo'); // Estado para o texto do botão

  const toggleButtonText = (isVisible) => {
    setButtonText(isVisible ? 'Fechar' : 'Exemplo');
  };

  const { title, content, image } = orientations[fieldKey] || {}; // Busca a orientação correspondente

  if (!title || !content) {
    console.warn(`Nenhuma orientação encontrada para o campo: ${fieldKey}`);
    return null; // Renderiza `null` se não encontrar orientação
  }

  const popover = (
    <Popover id={`popover-${fieldKey}`} style={{ maxWidth: '700px', backgroundColor: '#ccc' }}>
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        <p>{content}</p>
        {image && (
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <img src={image} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={popover}
      onToggle={(isVisible) => toggleButtonText(isVisible)}
      rootClose
    >
      <Button variant="outline-success" size="sm">{buttonText}</Button>
    </OverlayTrigger>
  );
};

export default PopoverGeneric;
