import React, { useState, useRef, useEffect } from "react";
import ContactImage from "../../assets/images/checklist.png";
import { toast, ToastContainer } from "react-toastify";
import WhatsApp from "../WhatsApp/WhatsApp";
import axios from "axios";

import "./ContactForm.css";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = ({ selectedPlan }) => {
  const [status, setStatus] = useState("Enviar");
  const formRef = useRef(null);
  const [cidades, setCidades] = useState([]); // Estado para armazenar as cidades
  const [cidadeSelecionada, setCidadeSelecionada] = useState(""); // Cidade selecionada

  // Função para buscar cidades do backend
  const fetchCidades = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cidades`);
      setCidades(response.data); // Atualiza o estado com as cidades retornadas
    } catch (error) {
      console.error("Erro ao buscar as cidades:", error);
      toast.error("Erro ao buscar as cidades. Tente novamente.", { position: "top-right", autoClose: 3000 });
    }
  };

  useEffect(() => {
    fetchCidades(); // Busca as cidades ao montar o componente
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Enviando...");
    const { name, email, tel, subject, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      tel: tel.value,
      city: cidadeSelecionada, // Inclui a cidade selecionada
      subject: subject.value || `Consulta sobre o plano: ${selectedPlan}`, // Inclui o plano no assunto
      message: `${message.value}\n\nPlano Selecionado: ${selectedPlan}`, // Inclui o plano na mensagem
    };

    try {
      const response = await fetch("https://sendmail.gigabytetelecom.com.br:5000/contact-site", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });

      const result = await response.json();

      if (response.ok && result.status === "Mensagem enviada e salva com sucesso.") {
        toast.success("Mensagem enviada com sucesso!", { position: "top-right", autoClose: 3000 });
        formRef.current.reset();
        setCidadeSelecionada(""); // Reseta a cidade selecionada
        setStatus("Enviar");
      } else {
        console.error("Resposta inesperada:", result);
        toast.error("Erro ao enviar mensagem. Tente novamente.", { position: "top-right", autoClose: 3000 });
        setStatus("Enviar");
      }
    } catch (error) {
      setStatus("Enviar");
      console.error("Erro ao enviar mensagem:", error);
      toast.error("Erro ao enviar mensagem. Tente novamente.", { position: "top-right", autoClose: 3000 });
    }
  };

  return (
    <div className="contact-box">
      <section id="contact">
        <div className="container">
          <div className="contact component__space" id="Contact">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="contact__box">
                  <div className="contact__meta">
                    <h1 className="hire__text">Formulário de contato</h1>
                    <p className="hire__text">
                      Preencha nosso formulário abaixo para possamos entrar em contato com você.
                    </p>
                    <p className="hire__text">
                      <strong>E-mail: </strong> faleconosco@gigabytetelecom.com.br
                    </p>
                    {selectedPlan && (
                      <p className="hire__text">
                        <strong>Plano Selecionado: </strong> {selectedPlan}
                      </p>
                    )}
                  </div>
                  <div className="input__box">
                    <form onSubmit={handleSubmit} ref={formRef}>
                      <input
                        type="text"
                        id="name"
                        required
                        className="contact name"
                        placeholder="Nome *"
                      />
                      <input
                        type="email"
                        id="email"
                        required
                        className="contact email"
                        placeholder="Email *"
                      />
                      <input
                        type="tel"
                        id="tel"
                        required
                        className="contact tel"
                        placeholder="Telefone *"
                      />
                      <select
                        id="cidade"
                        value={cidadeSelecionada}
                        onChange={(e) => setCidadeSelecionada(e.target.value)}
                        required
                        className="contact form-select"
                      >
                        <option value="">Cidade para atendimento *</option>
                        {cidades.map((cidade) => (
                          <option key={cidade._id} value={cidade.nome}>
                            {cidade.nome}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        className="contact subject"
                        placeholder="Escreva um assunto *"
                        id="subject"
                      />
                      <textarea
                        className="contact message"
                        name="message"
                        id="message"
                        required
                        placeholder="Escreva sua mensagem"
                        style={{ fontSize: "11px" }}
                      ></textarea>
                      <button type="submit" className="view__more contact pointer btn">
                        {status}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <img src={ContactImage} alt="" className="contact__img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
