import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import Login from '../../assets/icons/sessao-de-login.png';
// import api from './api';

import 'react-toastify/dist/ReactToastify.css';
import './LoginComponent.css';
import SeparatorBar from '../Separator/SeparatorBar';
import Bots from '../../assets/images/avatar2.png'

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  // Quando você definir o auth no login:
  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
  //       email,
  //       password,
  //     });

  //     const token = response.data.accessToken;
  //     const decoded = jwtDecode(token); // Decodifica o token
  //     setAuth({ token, userId: decoded.userId, userName: decoded.name }); // Armazena as informações no contexto
  //     console.log(decoded.userName);
  //     navigate("/menu");
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       setErrorMsg("Credenciais inválidas, por favor tente novamente.");
  //     } else {
  //       setErrorMsg("Credenciais inválidas, por favor tente novamente.");
  //     }
  //     console.error(error.response.data.msg);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login-site`, {
        email,
        password: password,
      });
  
      const { accessToken, user } = response.data;
  
      if (!accessToken || !user) {
        throw new Error("Resposta inválida do servidor");
      }
  
      // Armazene o token e o usuário no localStorage
      localStorage.setItem('token', accessToken);
      localStorage.setItem('user', JSON.stringify(user));
  
      // Atualize o contexto de autenticação (opcional)
      setAuth({ token: accessToken, user });
  
      toast.success('Login bem-sucedido!', { position: 'top-center' });
  
      // Redirecione após o login
      setTimeout(() => {
        navigate('/editar-curriculo');
      }, 3000);
    } catch (error) {
      console.error('Erro no login:', error);
      toast.error('Erro ao tentar fazer login. Credenciais inválidas!', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-container-bg'>
      <ToastContainer />
      <div className="container">
        <div className="content-login mx-auto text-center" style={{ width: "80%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                {/* Primeira coluna */}
                    <h2 className='bots'>Faça login agora para cadastrar ou visualizar seu currículo e aproveitar grandes oportunidades!</h2>
                <div className="col-sm-12 col-md-6">
                  <div className="bots">
                    <img src={Bots} alt="" />
                    
                  </div>
                </div>
                {/* Segunda coluna */}
                <div className="col-sm-12 col-md-6 gap-5 pr-5">
                  <div className='login-title'>
                    <img src={Login} alt="" />
                    <h2>Login</h2>
                  </div>
                  <SeparatorBar />
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Digite seu email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="senha">Senha:</label>
                      <input
                        type="password"
                        id="senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Digite sua senha"
                        required
                      />
                    </div>
                    <button type="submit" className="btn-login" disabled={loading}>
                      {loading ? 'Entrando...' : 'Entrar'}
                    </button>
                  </form>
                  <div className='signIn'>
                    <p>Ainda não é cadastrado? <a href="/register-site">Cadastrar</a> </p>
                  </div>
                </div>
                <p>Se você busca crescimento profissional, desafios estimulantes e deseja fazer a diferença, faça login para preencher seu currículo e venha construir o futuro conosco!</p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;

