import React, { useState } from 'react';
import ReactQuill from "react-quill";
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Form, Button, FormControl, FormGroup, FormCheck, ButtonGroup, ToggleButton } from 'react-bootstrap';
import PopoverGeneric from './PopoverGeneric';

//ICONS
import { ReactComponent as Calendar } from '../../assets/img/calendario.svg';
import { ReactComponent as House } from '../../assets/img/iconmonstr-home-6.svg';
import { ReactComponent as Phone } from '../../assets/img/phone.svg';
import { ReactComponent as Photo } from '../../assets/img/photo.svg';
import { ReactComponent as City } from '../../assets/img/city-svgrepo-com.svg';

import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css'; // Estilo padrão do ReactQuill
import './styles.css';

const Formulario = () => {
  const [nascimento, setNascimento] = useState('');
  const [formatoValido, setFormatoValido] = useState(true);
  const [foto, setFoto] = useState(null);
  const [endereco, setEndereco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [perfil, setPerfil] = useState('');
  const [experiencias, setExperiencias] = useState(['']);
  const [formacoes, setFormacoes] = useState(['']);
  const [cursos, setCursos] = useState(['']);

  const cities = [
    "Aparecida de Goiânia",
    "Braslândia",
    "Carmo do Rio Verde",
    "Ceres",
    "Goiânia",
    "Jaraguá",
    "Nova Glória",
    "Rialma",
    "Rianápolis",
    "São Francisco",
    "Uruaçu",
    "Uruana",
  ];

  const [selectedCity, setSelectedCity] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");


  // Função para manipular a seleção
  const handleChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleMaritalStatusChange = (event) => {
    setMaritalStatus(event.target.value);
  };

  // Novo estado para áreas de interesse (switches)
  const [cidadesInteresse, setCidadesInteresse] = useState({
    goiania: false,
    jaragua: false,
    ceres: false,
    uruana: false,
    uruacu: false
  });

  // Novo estado para áreas de interesse (switches)
  const [areasInteresse, setAreasInteresse] = useState({
    ti: false,
    administrativa: false,
    callCenter: false,
    atendimentoPublico: false,
    vendedor: false
  });

  const [redesSociais, setRedesSociais] = useState([{ tipo: '', url: '' }]);

  // Função para lidar com a alteração dos switches
  const handleAreaInteresseChange = (area) => {
    setAreasInteresse(prevState => ({
      ...prevState,
      [area]: !prevState[area]
    }));
  };

  // Função para lidar com a alteração dos switches
  const handleCidadeInteresseChange = (cidade) => {
    setCidadesInteresse(prevState => ({
      ...prevState,
      [cidade]: !prevState[cidade]
    }));
  };

  // Função para adicionar mais campos de cursos
  const adicionarCurso = () => {
    setCursos([...cursos, '']);
  };

  // Função para adicionar mais campos de experiência
  const adicionarExperiencia = () => {
    setExperiencias([...experiencias, '']);
  };

  // Função para adicionar mais campos de formação
  const adicionarFormacao = () => {
    setFormacoes([...formacoes, '']);
  };

  // Função para alterar as entradas de experiência
  const handleCursoChange = (index, content) => {
    const novasCursos = [...cursos];
    novasCursos[index] = content;
    setCursos(novasCursos);
  };

  // Função para alterar as entradas de experiência
  const handleExperienciaChange = (index, content) => {
    const novasExperiencias = [...experiencias];
    novasExperiencias[index] = content;
    setExperiencias(novasExperiencias);
  };

  // Função para alterar as entradas de formação
  const handleFormacaoChange = (index, content) => {
    const novasFormacoes = [...formacoes];
    novasFormacoes[index] = content;
    setFormacoes(novasFormacoes);
  };

  // Função para remover uma curso
  const removerCurso = (index) => {
    const novasCursos = [...cursos];
    novasCursos.splice(index, 1);
    setCursos(novasCursos);
  };

  // Função para remover uma experiência
  const removerExperiencia = (index) => {
    const novasExperiencias = [...experiencias];
    novasExperiencias.splice(index, 1);
    setExperiencias(novasExperiencias);
  };

  // Função para remover uma formação
  const removerFormacao = (index) => {
    const novasFormacoes = [...formacoes];
    novasFormacoes.splice(index, 1);
    setFormacoes(novasFormacoes);
  };

  const handleRedeSocialChange = (index, field, value) => {
    const novasRedes = [...redesSociais];
    novasRedes[index][field] = value;
    setRedesSociais(novasRedes);
  };

  const adicionarRedeSocial = () => {
    setRedesSociais([...redesSociais, { tipo: '', url: '' }]);
  };

  const removerRedeSocial = (index) => {
    const novasRedes = redesSociais.filter((_, i) => i !== index);
    setRedesSociais(novasRedes);
  };

  const handleFotoChange = (event) => {
    setFoto(event.target.files[0]);
  };

  const handleEnderecoChange = (event) => {
    setEndereco(event.target.value);
  };

  const handleTelefoneChange = (event) => {
    setTelefone(event.target.value);
  };

  const handlePerfilChange = (content) => {
    setPerfil(content);
  };

  const handleNascimentoChange = (event) => {
    const inputDate = event.target.value;
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    setNascimento(inputDate);

    if (regex.test(inputDate)) {
      setFormatoValido(true);
    } else {
      setFormatoValido(false);
    }
  };

  const handleInvalidFormatFocus = (event) => {
    // Dê foco ao campo quando o formato for inválido
    if (!formatoValido) {
      event.target.focus();
    }
  };

  const [redirecionarParaDetalhes, setRedirecionarParaDetalhes] = useState(false);
  const [idDoCurriculoCadastrado, setIdDoCurriculoCadastrado] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    if (!token) {
      toast.error('Você não está autenticado. Faça login novamente.', { position: 'top-right' });
      return;
    }

    const formData = new FormData();
    formData.append('foto', foto);
    formData.append('endereco', endereco);
    formData.append('telefone', telefone);
    formData.append('selectedCity', selectedCity);
    formData.append('gender', gender);
    formData.append('maritalStatus', maritalStatus);
    formData.append('perfil', perfil);
    formData.append('experiencias', JSON.stringify(experiencias));
    formData.append('formacoes', JSON.stringify(formacoes));
    formData.append('redesSociais', JSON.stringify(redesSociais));
    formData.append('cursos', JSON.stringify(cursos));
    formData.append('areasInteresse', JSON.stringify(areasInteresse));
    formData.append('cidadesInteresse', JSON.stringify(cidadesInteresse));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/curriculos`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Adicione o token no cabeçalho
        },
      });

      const idDoCurriculoCadastrado = response.data._id;
      setIdDoCurriculoCadastrado(idDoCurriculoCadastrado);
      toast.success('Currículo cadastrado com sucesso!', { position: 'top-center' });

      setTimeout(() => {
        setRedirecionarParaDetalhes(true);
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Você não está autorizado. Verifique seu login.', { position: 'top-center' });
      } else {
        toast.error('Erro ao enviar o currículo. Tente novamente.', { position: 'top-center' });
      }
    }
  };

  console.log('redirecionarParaDetalhes:', redirecionarParaDetalhes); // Debug
  console.log('idDoCurriculoCadastrado:', idDoCurriculoCadastrado); // Debug


  return (
    <div className='container'>
      <ToastContainer />
      {redirecionarParaDetalhes && idDoCurriculoCadastrado && <Navigate to={`/editar-curriculo`} />}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <div className='row'>
            <div className='orientacao'>
              <div className='saudacao'>
                <span>
                  Olá futuro colaborador da Gigabyte Telecom! Estamos animados para conhecê-lo melhor. Por favor, preencha o formulário abaixo para nos contar mais sobre suas experiências, habilidades e personalidade.
                </span>
              </div>

              <hr />
            </div>
          </div>

          <div className='row'>
            <h5>Informações de Identificação</h5>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="endereco">Endereço:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><House /></span>
                <FormControl type="text" placeholder="Endereço" id="endereco" value={endereco} onChange={handleEnderecoChange} required />
              </div>
              <p>Compartilhe seu endereço atual.</p>
            </div>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="cidade">Cidade:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><City /></span>
                <FormControl type="text" placeholder="Cidade" id="city" value={selectedCity} onChange={handleChange} required />
              </div>
              {selectedCity && (
                <p style={{ marginTop: "5px", color: "#555" }}>
                  Você selecionou: <strong>{selectedCity} </strong>
                </p>
              )}
              {selectedCity ? '' : <p>Qual a sua cidade?</p>}

            </div>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="telefone">Telefone: </Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Phone /></span>
                <FormControl type="text" placeholder="Telefone" id="telefone" value={telefone} onChange={handleTelefoneChange} required />
              </div>
              <p>Informe um número de telefone para facilitar nossa comunicação. </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-3'>
              <Form.Label htmlFor="datanascimento">Data de Nascimento: </Form.Label>
              <div className={`text-icon ${formatoValido ? '' : 'campo-invalido'}`}>
                <span className="input-group-text" id="addon-wrapping"><Calendar /></span>
                <FormControl
                  type="text"
                  placeholder="Digite sua data de nascimento"
                  id="nascimento"
                  value={nascimento}
                  onChange={handleNascimentoChange}
                  className={formatoValido ? '' : 'formato-invalido'} // Adiciona classe para feedback visual
                />
              </div>
              <p>Por favor, informe a data no formato dd/mm/aaaa. </p>
              {!formatoValido && <p className="mensagem-erro">Insira sua data de nascimento no formato dd/mm/aaaa.</p>}
            </div>

            <div className='col-sm-12 col-md-3'>
              <Form.Label htmlFor="datanascimento">Foto: </Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Photo /></span>
                <FormControl type="file" id="foto" onChange={handleFotoChange} required />
              </div>
              <p>Anexe uma foto sua para que possamos reconhecê-lo. (Dica: Opte por uma foto profissional.)</p>

            </div>

            <div className='col-sm-12 col-md-2'>
              {/* Botões de rádio para gênero */}
              <div style={{ marginBottom: "20px" }}>
                <Form.Label htmlFor="genero">Gênero: </Form.Label>
                <div className='genero'>
                  <Form.Label htmlFor="genero" style={{ marginRight: "10px", fontWeight: "400", textAlign: "center" }}>
                    <ButtonGroup className="genero-btn">
                      <ToggleButton
                        id="masculino"
                        type="checkbox"
                        variant="outline-primary"
                        value="Masculino"
                        checked={gender === "Masculino"}
                        onChange={handleGenderChange}
                        style={{ fontSize: "11px", padding: "9px" }}
                      >
                        Masculino
                      </ToggleButton>
                    </ButtonGroup>
                  </Form.Label>
                  <Form.Label htmlFor="genero" style={{ marginRight: "10px", fontWeight: "400", textAlign: "center", }}>
                    <ButtonGroup className="genero-btn">
                      <ToggleButton
                        id="feminino"
                        type="checkbox"
                        variant="outline-success"
                        value="Feminino"
                        checked={gender === "Feminino"}
                        onChange={handleGenderChange}
                        style={{ fontSize: "11px", padding: "9px" }}
                      >
                        Feminino
                      </ToggleButton>
                    </ButtonGroup>
                  </Form.Label>
                </div>
                {gender && (
                  <p style={{ marginTop: "10px", color: "#555" }}>
                    Gênero selecionado: <strong>{gender}</strong>
                  </p>
                )}
              </div>
            </div>
            <div className='col-sm-12 col-md-4 teste-form'>
              {/* Botões de rádio para estado civil */}
              <div style={{ marginBottom: "20px" }}>
                <Form.Label htmlFor="estadocivil">Estado Civil: </Form.Label>
                <div className='genero'>
                  <Form.Label htmlFor="estadocivil" style={{ marginRight: "10px", fontWeight: "400", textAlign: "center" }}>
                    <ButtonGroup className="genero-btn">
                      <ToggleButton
                        id="solteiro"
                        type="checkbox"
                        variant="outline-success"
                        value="Solteiro(a)"
                        checked={maritalStatus === "Solteiro(a)"}
                        onChange={handleMaritalStatusChange}
                        style={{ fontSize: "11px", padding: "9px" }}
                      >
                        Solteiro(a)
                      </ToggleButton>
                    </ButtonGroup>
                  </Form.Label>
                  <Form.Label htmlFor="estadocivil" style={{ marginRight: "10px", fontWeight: "400", textAlign: "center" }}>
                    <ButtonGroup className="genero-btn">
                      <ToggleButton
                        id="casado"
                        type="checkbox"
                        variant="outline-warning"
                        value="Casado(a)"
                        checked={maritalStatus === "Casado(a)"}
                        onChange={handleMaritalStatusChange}
                        style={{ fontSize: "11px", padding: "9px" }}
                      >
                        Casado(a)
                      </ToggleButton>
                    </ButtonGroup>
                  </Form.Label>
                  <Form.Label htmlFor="estadocivil" style={{ marginRight: "10px", fontWeight: "400", textAlign: "center" }}>
                    <ButtonGroup className="genero-btn">
                      <ToggleButton
                        id="outro"
                        type="checkbox"
                        variant='outline-danger'
                        value="Outro"
                        checked={maritalStatus === "Outro"}
                        onChange={handleMaritalStatusChange}
                        style={{ fontSize: "11px", padding: "9px" }}
                      >
                        Outro
                      </ToggleButton>
                    </ButtonGroup>
                  </Form.Label>
                </div>
                {maritalStatus && (
                  <p style={{ marginTop: "10px", color: "#555" }}>
                    Estado civil selecionado: <strong>{maritalStatus}</strong>
                  </p>
                )}
              </div>
            </div>
          </div>
          <br /><hr /><br />
          <div className='row'>
            <h5>Informe a sua Formação Acadêmica</h5>
            <div className='col-sm-6 mb-3'>
            <div className='text-popover'>
              <Form.Label htmlFor="formacao">Formação Escolar: </Form.Label>
              <PopoverGeneric fieldKey="formacao" buttonText="Exemplo"/>
            </div>
              {formacoes.map((formacao, index) => (
                <div key={index} className='text-textarea'>
                  {/* <span className="input-group-text" id="addon-wrapping"><Education /></span> */}
                  <ReactQuill
                    as="textarea"
                    style={{ fontSize: "11px", paddingTop: "8px", height: `${11 * 1.5 * 5}px`, }}
                    placeholder="Poderia informar a sua formação escolar? Por exemplo, indique o nível de escolaridade, o nome da instituição e o período em que concluiu o curso."
                    value={formacao}
                    onChange={(content) => handleFormacaoChange(index, content)}
                    required
                  />
                  {formacoes.length > 1 && (
                    <Button variant="outline-danger" onClick={() => removerFormacao(index)} style={{ marginTop: "3rem" }}>Remover</Button>
                  )}
                </div>
              ))}
              <div>
                <p style={{ marginTop: "3rem" }}>Descreva sua formação acadêmica, indicando cursos, instituições e datas. </p>
                <Button variant="outline-primary" size='sm' onClick={adicionarFormacao}>
                  <i class="fa fa-plus" aria-hidden="true" />  Formação
                </Button>
              </div>
            </div>

            <div className='col-sm-12 col-md-6'>
            <div className='text-popover'>
              <Form.Label htmlFor="curso">Outros Cursos: </Form.Label>
              <PopoverGeneric fieldKey="curso" buttonText="Exemplo"/>
            </div>
              {cursos.map((curso, index) => (
                <div key={index} className='text-textarea'>
                  {/* <span className="input-group-text" id="addon-wrapping"><Formation /></span> */}
                  <ReactQuill
                    as='textarea'
                    style={{ fontSize: "11px", paddingTop: "8px", height: `${11 * 1.5 * 5}px`, }}
                    placeholder="Poderia listar seus cursos profissionais, formações complementares e conhecimentos em informática? Inclua o nome dos cursos, certificações obtidas e as ferramentas ou linguagens que domina."
                    value={curso}
                    onChange={(content) => handleCursoChange(index, content)}
                    required
                  />
                  {cursos.length > 1 && (
                    <Button variant="outline-danger" onClick={() => removerCurso(index)} style={{ marginTop: "3rem" }}>Remover</Button>
                  )}
                </div>
              ))}
              <div>
                <p style={{ marginTop: "3rem" }}>Liste seus cursos profissionais anteriores. Inclua o nome das empresas, suas funções e as datas de início e término.</p>
                <Button variant="outline-primary" size='sm' onClick={adicionarCurso}>
                  <i class="fa fa-plus" aria-hidden="true" /> Curso
                </Button>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className='row teste-row'>
          <h5>Informe suas Experiências profissionais e o seu Perfil pessoal</h5>
            <div className='col-sm-12 col-md-6 '>
              <div className='text-popover'>                
                <Form.Label htmlFor="experiencia">Experiência Profissional:</Form.Label>
                <PopoverGeneric fieldKey="experiencia" buttonText="Exemplo"/>
              </div>
              {experiencias.map((experiencia, index) => (
                <div key={index} className='text-textarea'>
                  {/* <span className="input-group-textarea" id="addon-wrapping"><Formation /></span> */}
                  <ReactQuill
                    theme="snow"
                    value={experiencia}
                    placeholder="Poderia descrever suas experiências profissionais anteriores? Informe em quais empresas trabalhou, quais funções desempenhou e em que período cada experiência ocorreu."
                    onChange={(content) => handleExperienciaChange(index, content)}
                    style={{ fontSize: "11px", paddingTop: "8px", height: `${11 * 1.5 * 5}px`, }}
                  />

                  {experiencias.length > 1 && (
                    <Button variant="outline-danger" onClick={() => removerExperiencia(index)} style={{ marginTop: "3rem" }}>Remover</Button>
                  )}
                </div>
              ))}
              <div>
                <p style={{ marginTop: "3rem" }}>Liste suas experiências profissionais anteriores. Inclua o nome das empresas, suas funções e as datas de início e término.</p>
                <Button variant="outline-primary" size='sm' onClick={adicionarExperiencia}>
                  <i className="fa fa-plus" aria-hidden="true" /> Experiência
                </Button>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 '>
              <div className='text-popover'>
                <Form.Label htmlFor="perfil">Perfil Pessoal:</Form.Label>
                <PopoverGeneric fieldKey="perfil" buttonText="Exemplo" />
              </div>
              <div className='text-textarea'>
                {/* <span className="input-group-text" id="addon-wrapping"><img src={Work} alt='foto' /></span> */}
                <ReactQuill
                  as="textarea"
                  style={{ fontSize: "11px", paddingTop: "8px", height: `${11 * 1.5 * 5}px`, }}
                  placeholder="Poderia descrever seu perfil profissional, destacando suas principais habilidades e aptidões? Inclua tanto competências técnicas quanto comportamentais que sejam relevantes para a vaga."
                  value={perfil}                  
                  onChange={handlePerfilChange}
                  required />
              </div>
              <div>
                <p style={{ marginTop: "3rem" }}>Conte-nos um pouco sobre quem você é, suas paixões e valores.</p>
              </div>
            </div>
          </div>
          <br /><hr /><br />
          <div className='row'>
            <div className='col-sm-12 col-md-3'>
              {/* Seção de Áreas de Interesse */}
              <div className="mb-3">
                <h5>Qual sua Área de Interesse?</h5>
                <FormCheck
                  type="switch"
                  id="switch-ti"
                  label="Área Técnica TI"
                  checked={areasInteresse.ti}
                  onChange={() => handleAreaInteresseChange('ti')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-administrativa"
                  label="Área Administrativa"
                  checked={areasInteresse.administrativa}
                  onChange={() => handleAreaInteresseChange('administrativa')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-callcenter"
                  label="Call Center"
                  checked={areasInteresse.callCenter}
                  onChange={() => handleAreaInteresseChange('callCenter')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-atendimento-publico"
                  label="Atendimento ao Público"
                  checked={areasInteresse.atendimentoPublico}
                  onChange={() => handleAreaInteresseChange('atendimentoPublico')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-vendedor"
                  label="Vendedor"
                  checked={areasInteresse.vendedor}
                  onChange={() => handleAreaInteresseChange('vendedor')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
              </div>
            </div>

            {/* Cidades */}
            <div className='col-sm-12 col-md-3'>
              <div className="mb-3">
                <h5>Qual cidade deseja trabalhar?</h5>
                <FormCheck
                  type="switch"
                  id="switch-goiania"
                  label="Goiânia"
                  checked={cidadesInteresse.goiania}
                  onChange={() => handleCidadeInteresseChange('goiania')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-jaragua"
                  label="Jaraguá"
                  checked={cidadesInteresse.jaragua}
                  onChange={() => handleCidadeInteresseChange('jaragua')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-ceres"
                  label="Ceres"
                  checked={cidadesInteresse.ceres}
                  onChange={() => handleCidadeInteresseChange('ceres')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-uruana"
                  label="Uruana"
                  checked={cidadesInteresse.uruana}
                  onChange={() => handleCidadeInteresseChange('uruana')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-uruacu"
                  label="Uruaçu"
                  checked={cidadesInteresse.uruacu}
                  onChange={() => handleCidadeInteresseChange('uruacu')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <h5>Informe suas Redes Sociais</h5>
              {/* <Form.Label htmlFor="redes-sociais">Redes Sociais:</Form.Label> */}
              {redesSociais.map((rede, index) => (
                <div key={index} className="text-icon mb-3">
                  <div className="select-input">
                    <div className='select-rede'>
                      <Form.Select
                        value={rede.tipo}
                        style={{ fontSize: "11px", paddingTop: "8px" }}
                        onChange={(e) => handleRedeSocialChange(index, 'tipo', e.target.value)}
                        required
                      >
                        <option value="">Selecione</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Github">Github</option>
                        <option value="Linkedin">Linkedin</option>
                      </Form.Select>
                    </div>
                    <div className='url-rede'>
                      <FormControl
                        type="url"
                        placeholder="URL da rede social"
                        value={rede.url}
                        onChange={(e) => handleRedeSocialChange(index, 'url', e.target.value)}
                        required

                      />
                    </div>
                    {redesSociais.length > 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={() => removerRedeSocial(index)}
                      >
                        Remover
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <p>Adicione suas redes sociais, selecionando o tipo e fornecendo a URL correspondente.</p>

              <Button variant="outline-primary" size='sm' onClick={adicionarRedeSocial}>
                <i class="fa fa-plus" aria-hidden="true" /> Redes
              </Button>

            </div>
          </div>

          <div className='orientacao'>
            Observações:

            Certifique-se de preencher todas as informações com precisão.
            Seja transparente e autêntico nas suas respostas.
            Este é um espaço para nos mostrar o que o torna único, então sinta-se à vontade para adicionar detalhes que considere relevantes.
            Obrigado por dedicar seu tempo para preencher nosso formulário! Aguardamos ansiosamente para conhecer mais sobre você e explorar como sua jornada pode se alinhar à Gigabyte Telecom. Boa sorte!
          </div>
          <br />
          {/* {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>} */}
          <Button type="submit">Enviar</Button>
          <hr />
        </FormGroup>
      </Form>
    </div>
  );
};


export default Formulario;
